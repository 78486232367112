<template>
  <Container class="d-print-block">
    <div class="d-flex justify-space-between pr-3 pl-3 mb-1">
      <ClinicDetail flexDirection="column" style="width: 50%">
        <span class="normal__case w-100">
          <b class="bold__case" v-html="isVnLanguage ? 'PK: ' : 'Clinic: '"></b>
          {{ clinic.clinicName }}
        </span>
        <span class="normal__case w-100">
          <b class="bold__case" v-html="isVnLanguage ? 'ĐC: ' : 'Addr: '"></b>
          {{ clinic.address }}
        </span>
        <span class="normal__case w-100">
          <b class="bold__case" v-html="isVnLanguage ? 'ĐT: ' : 'Phone: '"></b>
          {{ clinic.hotline }}
        </span>
      </ClinicDetail>
      <ImageLogoBox>
        <img
          class="imageLogoBox_Image"
          src="../../../assets/CT_logo.png"
          alt="Logo Cao thang"
        />
      </ImageLogoBox>
    </div>
    <TitlePrescription>
      <span
        v-html="isVnLanguage ? 'Phiếu đăng kí khám' : 'Medical registration'"
      ></span>
    </TitlePrescription>
    <div class="oval-border">
      <span
        v-html="numberOrder"
        style="display: flex; justify-content: center; padding-top: 9px"
      ></span>
    </div>
    <div class="text-center mt-13">
      <span v-html="startTime" class="admissiontTime"></span>
    </div>

    <PatientInfo class="mt-2">
      <div class="d-flex justify-space-between w-100 pl-3 pr-3">
        <div class="d-flex flex-column">
          <label class="patientInfo__item">
            {{ isVnLanguage ? "Họ tên: " : "Name: " }}
            <span class="upper__case">{{ personalInfo.fullName }}</span>
          </label>
          <label class="patientInfo__item">
            {{ isVnLanguage ? "Giới tính: " : "Gender: " }}
            <span class="bold__case">
              {{ personalInfo.genderTypeName }}
            </span>
          </label>
          <label class="patientInfo__item">
            {{ isVnLanguage ? "Địa chỉ: " : "Address: " }}
            <span class="bold__case">{{ personalInfo.address }}</span>
          </label>
        </div>

        <div class="d-flex flex-column">
          <label class="patientInfo__item">
            {{ isVnLanguage ? "Mã hồ sơ: " : "Patient ID: " }}
            <span class="bold__case">{{ personalInfo.patientID }}</span>
          </label>
          <span class="patientInfo__item">
            {{ isVnLanguage ? "Ngày sinh: " : "BirthDay: " }}
            {{ formatDate(personalInfo.birthDate) }}
            <b class="bold__case">
              ({{ isVnLanguage ? "Tuổi: " : "Ages: " }}
              {{
                new Date().getFullYear() -
                new Date(personalInfo.birthDate).getFullYear()
              }})
            </b>
          </span>
        </div>
      </div>
    </PatientInfo>

    <DetailPrescription class="mt-2">
      <TableContent>
        <thead>
          <tr>
            <th colspan="6">Dịch vụ</th>
            <!-- <th colspan="1">Bác sĩ</th>
            <th colspan="2">Phòng</th> -->
          </tr>
        </thead>
        <tbody v-for="(item, index) in orderItems" :key="index">
          <tr class="namePackage">
            <td
              :colspan="item.typeID == 3 ? '6' : '5'"
              class="nameService text-left"
            >
              {{ item.itemName }}
            </td>
            <td style="width: 40px" v-if="item.typeID != 3"></td>
            <!-- <td colspan="1">Bác sĩ</td>
            <td colspan="2">Phòng</td> -->
          </tr>
          <template v-if="item.childrenItems.length">
            <tr
              v-for="(item1, index) in item.childrenItems"
              :key="index"
              class="childNamePackage"
            >
              <td colspan="5" class="nameAxPx text-left ml-2">
                <span>{{ item1.itemName }}</span>
              </td>
              <td style="width: 40px"></td>
              <!-- <td colspan="1">Bác sĩ</td>
              <td colspan="2">Phòng</td> -->
            </tr>
          </template>
        </tbody>
      </TableContent>
    </DetailPrescription>
  </Container>
</template>

<script>
import { awaitAll } from "@/plugins/helper";
import moment from "moment";
import QueueService from "../../../services/queue";
import PatientService from "@/services/patient";
import AdmissionService from "@/services/admission";
import BrandService from "@/services/brand";
import i18n from "@/plugins/i18n";

import {
  Container,
  ClinicDetail,
  ImageLogoBox,
  TitlePrescription,
  PatientInfo,
  TableContent,
  DetailPrescription,
} from "./css/style";
export default {
  name: "MedicinePrescription",
  components: {
    Container,
    ClinicDetail,
    ImageLogoBox,
    TitlePrescription,
    PatientInfo,
    TableContent,
    DetailPrescription,
  },
  props: {
    hideRawData: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    userName() {
      let user = this.$store.getters.userData;
      return user.fullName;
    },
    today() {
      return moment().format("DD-MM-YYYY");
    },
  },
  data: () => ({
    printBlock: true,
    nonePrint: true,
    isVnLanguage: i18n.locale == "vn",
    personalInfo: {},
    clinic: {},
    // patientID: "",
    numberOrder: 0,
    startTime: null,
    orderItems: [],
  }),
  created() {
    this.print();
  },
  methods: {
    async print() {
      await awaitAll([
        this.renderPatientDetail(),
        this.renderClinicDetail(),
        this.getStartTimePatient(),
        this.renderListService(),
      ]);
      setTimeout(() => {
        window.print();
        // setTimeout(() => {
        //   window.close();
        // }, 500);
      }, 500);
    },
    formatDate(date) {
      if (!date) return "";
      return moment(date).format("DD-MM-YYYY");
    },
    jsUcfirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    async renderPatientDetail() {
      var patientID = this.$route.params.patientID;
      var result = await PatientService.getPatientDetail(patientID);
      if (result.error) {
        console.log("Cannot get patient info. Please try again later!");
        return;
      }
      const { personalInfo } = result;
      this.personalInfo = personalInfo;
      this.personalInfo.genderTypeName = this.personalInfo.genderType - 1;
      this.numberOrder = this.$route.params.number;
    },
    async getStartTimePatient() {
      this.numberOrder = this.$route.params.number;
      var result = await QueueService.getOverviewQueue();
      if (result.error) {
        this.showError("Can not retrieve data!");
        return;
      }
      console.log(result);
      var data = result.listPatient.find(
        (e) => e.queueNumber == this.numberOrder
      );
      this.startTime = moment(data.startTime)
        .utcOffset("+1400")
        .format("DD/MM/YYYY HH:mm");
    },
    async renderClinicDetail() {
      let clinicID = this.$store.getters.userData.clinicID;
      var result = await BrandService.getBrandClinicByID(clinicID);
      if (result.error) {
        console.log("Cannot get clinic info. Please try again later!");
        return;
      }
      this.clinic = result;
    },
    async renderListService() {
      let episodeRecordID = this.$route.params.episodeRecordID;
      var result = await AdmissionService.getEpisodeRecordSummary(
        episodeRecordID
      );
      if (result.error) {
        console.log("Cannot get admission summary. Please try again later!");
        return;
      }
      console.log(result);
      this.orderItems = result.orderItems.map((item) => {
        if (item.childrenItems.length) {
          item.childrenItems = item.childrenItems.map((itemChild) => {
            itemChild.itemName = `- ${itemChild.itemName}`;
            return itemChild;
          });
        }
        return item;
      });
    },
  },
};
</script>

<style scoped lang ="scss">
.oval-border {
  height: 50px;
  width: 100px;
  border: 2px #666 solid;
  border-radius: 50%;
  position: absolute;
  left: 35%;
}
@media print {
  .mp-0 {
    margin: 0px;
    padding: 0px;
  }
  .oval-border {
    height: 50px;
    width: 100px;
    border: 2px #666 solid;
  }
  .medicineItem__info {
    padding: 1px;
  }
  .admissiontTime {
    font-size: 9px;
  }
  .medicineItem__note {
    padding: 0px;
    font-size: 10px;
  }
  .order {
    font-size: 12px;
  }
  .name {
    font-size: 12px;
  }
  .unit {
    font-size: 12px;
  }
  td {
    padding: 1px;
    font-size: 10px;
  }
  .content__prescription {
    font-size: 9px;
  }
  .title__prescription {
    font-size: 9px;
  }
  .diagnosisBox__content {
    font-size: 12px;
    margin-left: 5px;
  }
  .footer__content {
    margin-top: 3px;
  }
  .nameService {
    max-width: 50px;
    font-weight: bold;
  }
  .nameAxPx {
    margin-left: 5px;
  }
}
</style>